// nextjs will complain if you attempt a named import
import { auth } from "../services/firebase";
// import { reaction } from "mobx";

export default class User {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    if (typeof window !== "undefined") {
      auth.onAuthStateChanged(session => {
        if (session) {
          this.set.session(session);
        } else {
          auth.signInAnonymously();
        }
      });
    }
  }
  set = {
    session: (session = {}) => {
      // cache this users firestore path
      session.path = `users/${session.uid}`;
      // set
      this.session = session;
      // for debug puposes
      if (typeof window !== "undefined" && this.session.uid) {
        console.log(this);
      }
    }
  };
}
