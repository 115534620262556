// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
export default async function handler(req, res) {
  const json = await getData();

  res.json(json);
}

// TODO replace this with actual prod endpoint
// export const HOST =
//   process.env.NODE_ENV !== "production"
//     ? "http://10.2.75.251:8087"
//     : "https://us-central1-bytez-web-services.cloudfunctions.net/https_papers_mobile_render_hil";

export const HOST =
  "https://us-central1-bytez-web-services.cloudfunctions.net/https_papers_mobile_render_hil";

export async function getData(publisher, paperID) {
  const bboxes = await fetch(`${HOST}/${publisher}/${paperID}`).then(res =>
    res.json()
  );

  return bboxes;
}
