// nextjs will complain if you attempt a named import
import packageJSON from "../package.json";
import StackTrace from "stacktrace-js";
import { collection, firestore, addDoc } from "../services/firebase";
import Router from "next/router";

const { version: APP_VERSION } = packageJSON;

const isProduction =
  process.env.NODE_ENV === "production" &&
  typeof window !== "undefined" &&
  window.TEST === undefined &&
  window.location.hostname === "bytez.com";

// const isProduction = true;

const consoleError = console.error;

export default class Analytics {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => this.set.clientIP(data.ip));

    // NOTES ON TESTING THE HANDLER
    // Chrome Console runs its error handler in a separate context, you need to create your own event to trigger the global error handler.
    // To test, run this in the console:

    // customErrorEvent = new Event("error");
    // customErrorEvent.error = new Error("Test Error");
    // window.dispatchEvent(customErrorEvent);

    if (typeof window !== "undefined") {
      window.addEventListener(
        "error",
        event => this.logException(event.error, event),
        true
      );
      console.error = this.logException;
    }
  }

  set = {
    clientIP: clientIP => {
      this.clientIP = clientIP;
    }
  };

  async logAnalyticsToFirestore({ action, data = {} }) {
    if (!this._.main.fullyLoaded) {
      return;
    }

    const { query, pathname } = Router;

    const {
      publisher,
      paperID,
      // in the ?page is not a query param yet
      page: pageNum = 1
    } = query;

    const uid = this._?.user?.session?.uid;

    await addDoc(collection(firestore, `analytics/${uid}/events`), {
      clientIP: this.clientIP ?? null,
      pathname,
      publisher,
      paperID,
      hilPhase: this._.main.hilPhase,
      pageNum,
      created: new Date(),
      action,
      ...data
    });
  }

  logException = (error, ...rest) => {
    if (error) {
      if (isProduction && error.stack) {
        // send to error dashboard
        this.logToGoogleErrorReporting(error).catch(consoleError);
      }
      consoleError(error, ...rest);
    }
  };

  logToGoogleErrorReporting = async error => {
    const enrichedFrames = await StackTrace.fromError(error);

    const stringifiedFrames = enrichedFrames
      .map(sf => sf.toString())
      .join("\n\t at ");

    const stackTraceMessage = `${error.message}\n\tat\t${stringifiedFrames}`;

    // first frame is where the error originated
    const [
      // keep the defaults to allow for testing in prod via synthetic events, this is required info for the API below
      {
        fileName = "error from console",
        lineNumber = 0,
        functionName = "error from console"
      } = {}
    ] = enrichedFrames;

    const reportLocation = {
      filePath: fileName,
      lineNumber,
      functionName
    };

    if (error?.stack) {
      return fetch(
        "https://clouderrorreporting.googleapis.com/v1beta1/projects/human-in-the-loop-b726d/events:report?key=AIzaSyAq5hElVFOnHwvitu6GZ1WEcmtPDueGvSE",
        {
          method: "post",
          // mode: "no-cors",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            serviceContext: {
              service: "Bytez Human in the Loop App",
              version: APP_VERSION,
              resourceType: "firebase_domain"
            },
            message: stackTraceMessage,
            context: {
              user: this._?.user?.session?.uid,
              httpRequest: {
                userAgent: navigator.userAgent
              },
              reportLocation
            }
          })
        }
      ).catch(consoleError);
    }
  };
}
