import Router from "next/router";
import { reaction } from "mobx";
import { HOST } from "../pages/api/getPaper/[publisher]/[paperID]";

import {
  beToFeAliasMap,
  typesConceptuallyGrouped
} from "../classificationTypes";

export default class Main {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    reaction(
      () => this.bboxes,
      bboxes => {
        if (
          bboxes?.publisher !== this.results.publisher ||
          bboxes?.paperID !== this.results.paperID
        ) {
          this.set.results(bboxes);
        }
      }
    );
    reaction(
      () => this.classificationGroup,
      () => {
        this.set.selectedBlockIndex();
      }
    );
    reaction(
      () => this.pageIndex,
      () => {
        this._.analytics.logAnalyticsToFirestore({
          action: "page change"
        });

        this.set.classificationGroup(0);
        this.set.selectedBlockIndex(-1);
        this.set.navigatedBlockIndex();
        // this only needs to reset when in block editing phase
        if (!this.onFinalHilPhase) {
          this.set.navigatedBlockMap();
        }

        this.set.manuallyNavigating();
        this.set.pdfPageLoaded();
        this.set.zoomLevel();
      }
    );
    reaction(
      () => this.fullyLoaded,
      () => {
        this.set.firstLoadComplete(true);
      }
    );
  }
  set = {
    paper: (paper = {}) => {
      this.paper = paper;
    },
    bboxes: (bboxes = {}) => {
      this.bboxes = bboxes;
    },
    results: (results = {}) => {
      this.results = results;
    },
    numPages: (numPages = 0) => {
      this.numPages = numPages;
    },
    pageIndex: (pageIndex = 0) => {
      this.pageIndex = pageIndex;
    },
    selectedBlockIndex: (selectedBlockIndex = -1) => {
      this.selectedBlockIndex = selectedBlockIndex;
    },
    selectedSpanIndex: selectedSpanIndex => {
      this.selectedSpanIndex = selectedSpanIndex;
    },
    highlightedBlockIndex: (highlightedBlockIndex = -1) => {
      this.highlightedBlockIndex = highlightedBlockIndex;
    },
    legendType: legendType => {
      this.legendType = legendType;
    },
    classificationGroup: (classificationGroup = 0) => {
      this.classificationGroup = classificationGroup;
    },
    showLabels: (showLabels = true) => {
      this.showLabels = showLabels;
      if (showLabels) {
        this.set.manuallyNavigating(false);
      }
    },
    navigatedBlockIndex: navigatedBlockIndex => {
      this.navigatedBlockIndex = navigatedBlockIndex;
    },
    navigatedBlockMap: (navigatedBlockMap = new Map()) => {
      this.navigatedBlockMap = navigatedBlockMap;
    },
    manuallyNavigating: (manuallyNavigating = false) => {
      this.manuallyNavigating = manuallyNavigating;

      if (!(this.navigatedBlockIndex >= 0)) {
        this.set.navigatedBlockIndex(0);
      }

      // handles the first opening
      // hilphase portion is there because the navmap is set in stepInlineMathBlock as the logic breaks away from the usual pattern of block by block, it must first search for a block before setting the block
      if (
        manuallyNavigating &&
        this.navigatedBlockMap.size === 0 &&
        !this.onFinalHilPhase
      ) {
        const newNavigatedBlockMap = new Map();

        newNavigatedBlockMap.set(this.navigatedBlockIndex, {
          deleted: false
        });
        this.set.navigatedBlockMap(newNavigatedBlockMap);
      }
      if (manuallyNavigating) {
        this.set.showLabels(false);
      }
    },
    showBlockOrdering: (showBlockOrdering = false) => {
      this.showBlockOrdering = showBlockOrdering;
    },
    admin: (admin = false) => {
      this.admin = admin;
    },
    cheatSheet: (cheatSheet = true) => {
      this.cheatSheet = cheatSheet;
    },
    pdfPageLoaded: (loaded = false) => {
      this.pdfPageLoaded = loaded;
    },

    // used in bbox cropping when adjusting the boundaries of a box.
    // TODO remove this to see why it has to be set on page change, under 99% of circumstances I would find out why this can't be evaluated on the fly
    // for some reason you need the original zoom level that the page was loaded on, but due to nueroIPS deadline, moving on.
    // put this code directly in the crop function to see what it does
    // zoomLevel: () => {
    //   this.zoomLevel =
    //     typeof window === "undefined"
    //       ? undefined
    //       : window.devicePixelRatio ||
    //         window.screen.availWidth / document.documentElement.clientWidth;
    // },
    zoomLevel: () => {
      this.zoomLevel = typeof window === "undefined" ? undefined : 2;
    },
    firstLoadComplete: (firstLoadComplete = false) => {
      this.firstLoadComplete = firstLoadComplete;
    },
    showDeletedspans: (showDeletedspans = true) => {
      this.showDeletedspans = showDeletedspans;
    },
    showLifecycle: (showLifecycle = false) => {
      this.showLifecycle = showLifecycle;
    },
    keyboardListenerEnabled: (keyboardListenerEnabled = true) => {
      this.keyboardListenerEnabled = keyboardListenerEnabled;
    }
  };
  highlightBlock = block => {
    const blockIndex = this.blocks.findIndex(pageBlock => pageBlock === block);

    if (blockIndex >= 0) {
      this.set.highlightedBlockIndex(blockIndex);
      return;
    }

    this.set.highlightedBlockIndex();
  };
  makeDecision = ({ block, decision, actionDescriptor, merge = false }) => {
    const results = this.clone(this.results);
    const blockIndex = this.page.blocks.findIndex(
      pageBlock => pageBlock === block
    );
    const resultBlock = results.pages[this.pageIndex].blocks[blockIndex];

    this._.analytics.logAnalyticsToFirestore({
      action: `block decision ${actionDescriptor} ${resultBlock.type} ${
        decision?.type ? ` -> ${decision.type}` : ""
      }`
    });

    if (block?.new && decision.action === "delete") {
      // delete the new block, if they say delete
      results.pages[this.pageIndex].blocks.splice(blockIndex, 1);
    } else {
      // else update the block
      resultBlock.decision =
        merge && resultBlock.decision
          ? { ...resultBlock.decision, ...decision }
          : decision;
    }

    this.set.results(results);

    this.markPageAsUnchecked();
  };

  deleteBlock = block => {
    const blockIndex = this.page.blocks.findIndex(
      pageBlock => pageBlock === block
    );

    const newNavigatedBlockMap = new Map(this.navigatedBlockMap);

    newNavigatedBlockMap.set(blockIndex, { deleted: true });

    this.set.navigatedBlockMap(newNavigatedBlockMap);

    this.makeDecision({
      block,
      decision: { action: "delete" },
      actionDescriptor: "delete"
    });
  };

  makeDecisionOnSpan = ({
    spanIndex,
    decision,
    undo = false,
    actionDescriptor
  }) => {
    const results = this.clone(this.results);

    const blockIndex = this.page.blocks.findIndex(
      pageBlock => pageBlock === this.selectedBlock
    );

    const targetBlock = results.pages[this.pageIndex].blocks[blockIndex];

    const spans = targetBlock.decision?.spans ?? targetBlock.spans;

    const targetSpan = spans[spanIndex];

    this._.analytics.logAnalyticsToFirestore({
      action: `span ${actionDescriptor}`,
      data: {
        blockIndex,
        spanIndex
      }
    });

    // undo the span decision, if there are no more spans with decisions, remove inlineImage from the block's decision
    if (undo === true) {
      // make the decision on the SPAN
      targetSpan.decision = undefined;

      const newspans = [
        ...spans.slice(0, spanIndex),
        targetSpan,
        ...spans.slice(spanIndex + 1)
      ];

      // if there are no decisions on the spans, remove them
      const noDecisionsOnSpans = newspans.reduce(
        (acc, span) => span.decision && acc,
        true
      );

      if (noDecisionsOnSpans) {
        delete targetBlock?.decision.spans;
      } else {
        targetBlock.decision = {
          ...targetBlock.decision,
          spans: newspans
        };
      }
    }
    // create a decision on the block
    // if there is no decision on the block, then we add the decision
    // if there is a decision on the block, then we modify the existing decision
    else {
      // make the decision on the SPAN
      targetSpan.decision = {
        ...targetSpan.decision,
        ...decision
      };

      // make the decision on the BLOCK
      targetBlock.decision = {
        action: "correct",
        ...targetBlock.decision,
        spans: [
          ...spans.slice(0, spanIndex),
          targetSpan,
          ...spans.slice(spanIndex + 1)
        ]
      };
    }

    this.set.results(results);
  };

  undoBlock = block => {
    const newNavigatedBlockMap = new Map(this.navigatedBlockMap);

    const blockIndex = this.page.blocks.findIndex(
      pageBlock => pageBlock === block
    );

    newNavigatedBlockMap.set(blockIndex, {
      deleted: false
    });

    this.set.navigatedBlockMap(newNavigatedBlockMap);

    this.makeDecision({
      block,
      decision: undefined,
      actionDescriptor: "undo delete"
    });
  };

  stepBlock = (direction = "forward") => {
    let index;

    this._.analytics.logAnalyticsToFirestore({
      action: `block step ${direction}`,
      data: {
        blockIndex: this.navigatedBlockIndex
      }
    });

    if (this.navigatedBlockIndex === undefined) {
      index = 0;
    }

    if (direction === "forward") {
      if (this.navigatedBlockIndex < this.blocks.length - 1) {
        index = this.navigatedBlockIndex + 1;

        // remove this if we don't want skip over behavior for deleted blocks

        while (this.blocks[index]?.decision?.action === "delete") {
          index++;
        }
      } else {
        this.set.selectedBlockIndex();
        this.set.manuallyNavigating(false);
        this.set.showLabels(true);
        return;
      }
    }
    if (direction === "backward") {
      if (0 < this.navigatedBlockIndex) {
        index = this.navigatedBlockIndex - 1;

        while (this.blocks[index]?.decision?.action === "delete") {
          index--;
        }
      } else {
        this.set.selectedBlockIndex();
        this.set.manuallyNavigating(false);
        this.set.showLabels(true);
        return;
      }
    }

    if (index === undefined) {
      return;
    }

    this.set.navigatedBlockIndex(index);
    this.set.manuallyNavigating(true);
    // make sure you default to have the selected block set as whatever was last set while manually nav'ing
    this.set.selectedBlockIndex(this.navigatedBlockIndex);

    // update block set of what was nav'd to
    const newNavigatedBlockMap = new Map(this.navigatedBlockMap);

    newNavigatedBlockMap.set(index, {
      deleted: false
    });
    this.set.navigatedBlockMap(newNavigatedBlockMap);

    // update the page as complete if all blocks naved
    if (
      !this.page.blockNavComplete &&
      this.navigatedBlockMap.size === this.undeletedBlocks.length
    ) {
      const results = this.clone(this.results);

      results.pages[this.pageIndex].blockNavComplete = true;
      this.set.results(results);
    }
  };

  stepInlineMathBlock = (direction = "forward") => {
    this._.analytics.logAnalyticsToFirestore({
      action: `inline math block step ${direction}`
    });
    if (direction === "forward") {
      if (this.manuallyNavigating === false && this.navigatedBlockIndex) {
        this.set.manuallyNavigating(true);
        return;
      }

      // step spans before blocks

      let index = this.navigatedBlockIndex + 1;

      for (let i = this.pageIndex; i < this.results.pages.length; i++) {
        const blocks = this.results.pages[i].blocks;

        for (let j = index; j < blocks.length; j++) {
          const block = blocks[j];

          if (
            block &&
            block.spans.filter(({ isInlineImage }) => isInlineImage).length > 0
          ) {
            this.stepPageAndBlock(i, j);
            return;
          }
        }
        // reset the block index if the current page doesn't have any math blocks
        index = 0;

        this.set.manuallyNavigating(false);
        this.set.showLabels(true);
      }
    }
    if (direction === "backward") {
      if (
        this.manuallyNavigating === false &&
        this.navigatedBlockIndex !== undefined
      ) {
        this.set.manuallyNavigating(true);
        return;
      }

      let index = this.navigatedBlockIndex - 1;

      for (let i = this.pageIndex; i >= 0; i--) {
        const blocks = this.results.pages[i].blocks;

        for (let j = index; j >= 0; j--) {
          const block = blocks[j];

          if (
            block &&
            block.spans.filter(({ isInlineImage }) => isInlineImage).length > 0
          ) {
            // const spans = block.spans;
            // for (
            //   let k = this.selectedSpanIndex ? this.selectedSpanIndex : 0;
            //   k < spans.length;
            //   k++
            // ) {
            //   if (spans[k].isInlineImage) {
            //     this.set.selectedSpanIndex(k);
            //     this.stepPageAndBlock(i, j);
            //   }
            // }
            this.stepPageAndBlock(i, j);
            return;
          }
        }
        // reset the block index if the current page doesn't have any math blocks
        // we need to set it to the first block on the previous page

        const prevPage = this.results.pages[i - 1];
        if (prevPage) {
          index = prevPage.blocks.length - 1;
          continue;
        }
        break;
      }

      this.set.manuallyNavigating(false);
      this.set.showLabels(true);
    }
  };

  stepPageAndBlock(pageIndex, blockIndex) {
    this.set.pageIndex(pageIndex);
    this.set.navigatedBlockIndex(blockIndex);
    this.set.selectedBlockIndex(this.navigatedBlockIndex);
    this.set.manuallyNavigating(true);

    const newNavigatedBlockMap = new Map(this.navigatedBlockMap);

    const inlineMathBlockIndex = this.inlineMathBlocks.indexOf(
      this.blocks[blockIndex]
    );

    console.log("Stepping inline math block index: ", inlineMathBlockIndex);

    newNavigatedBlockMap.set(inlineMathBlockIndex, {
      deleted: false
    });

    this.set.navigatedBlockMap(newNavigatedBlockMap);

    Router.push({
      pathname: Router.pathname,
      query: {
        ...Router.query,
        page: pageIndex + 1
      }
    });
  }

  markPaperAsProcessed = () => {
    const results = this.clone(this.results);
    results.processed = true;

    this.set.results(results);
  };

  markPageAsChecked = () => {
    this._.analytics.logAnalyticsToFirestore({
      action: `page saved`
    });

    const results = this.clone(this.results);

    results.pages[this.pageIndex].checked = true;

    this.set.results(results);

    const key = `results-${this.paper.publisher}-${this.paper.paperID}`;

    localStorage.setItem(key, JSON.stringify(results));
  };

  // useful for debugging
  markPageAsUnchecked = () => {
    const results = this.clone(this.results);

    results.pages[this.pageIndex].checked = false;

    this.set.results(results);
    // this.set.navigatedBlockMap();
  };

  markAllPagesAsChecked = () => {
    const results = this.clone(this.results);

    for (const page of results.pages) {
      page.checked = true;
    }

    this.set.results(results);
  };

  shiftBlockByOne = (index, direction = "up") => {
    const results = this.clone(this.results);

    const blocks = results.pages[this.pageIndex].blocks;

    let adjustedIndex;

    if (direction === "up") {
      adjustedIndex = index - 1;
    }
    if (direction === "down") {
      adjustedIndex = index + 1;
    }

    if (0 <= adjustedIndex && adjustedIndex < blocks.length - 1) {
      const temp = blocks[index];

      blocks[index] = blocks[adjustedIndex];

      blocks[adjustedIndex] = temp;

      this.set.results(results);

      this.set.selectedBlockIndex(adjustedIndex);
    }
  };

  postOpToBackend = ({ operation = "save-progress", newHilPhase } = {}) => {
    if (operation === "process" && isNaN(newHilPhase)) {
      throw new Error(
        `newHilPhase must be set to a number between 1 and ${this.results.maxHilPhase}`
      );
    }

    // keep same hil phase if reviewing
    if (Router.pathname.includes("review")) {
      newHilPhase = this.hilPhase;
    }

    const url = `${HOST}/${this.paper.publisher}/${this.paper.paperID}`;

    console.log(`Hitting ${url} with operation: ${operation}`);
    console.log("Results are: ", this.clone(this.results));

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operation,
        hilJSON: this.results,
        ...(Number.isInteger(newHilPhase) ? { newHilPhase } : {}),
        // TODO replace this with the actual task they're on
        taskType: "all"
      })
    }).then(async res => {
      const json = await res.json();
      console.log("Server replied with: ", json);
    });
  };

  requestProcessingOfNextPhase = ({ revert = false } = {}) => {
    this._.analytics.logAnalyticsToFirestore({
      action: `paper submitted`,
      data: { reverted: revert }
    });
    return this.postOpToBackend({
      operation: "process",
      // if revert is true, that means we need to go back to normal block editing because something was found to be wrong during inline image editing
      newHilPhase: revert ? 0 : this.hilPhase < 2 ? this.hilPhase + 1 : 2
    });
  };

  validateBlocksForSubmission = () => {
    for (const [page, pageNum] of (this.results.pages ?? []).map((page, i) => [
      page,
      i + 1
    ])) {
      for (const [block, blockIndex] of page.blocks.map((block, i) => [
        block,
        i
      ])) {
        if (block.type === "" || block?.decision?.type === "") {
          return { blocksAreValid: false, pageNum, blockIndex };
        }
      }
    }

    return { blocksAreValid: true, pageNum: undefined };
  };

  _block = {
    // returns a default of 100, by 100 if no xy is specified
    computeBbox: (x, y) => {
      const LENGTH = 100;

      const x0 = x || this.page.width / 2 - LENGTH / 2;
      const y0 = y || this.page.height / 2 - LENGTH / 2;

      const x1 = x0 + LENGTH;
      const y1 = y0 + LENGTH;

      const bbox = [x0, y0, x1, y1];
      return bbox;
    },
    add: (x, y) => {
      const results = this.clone(this.results);

      const bbox = this._block.computeBbox(x, y);

      results.pages[this.pageIndex].blocks.push({
        new: true,
        bbox: bbox,
        ogBlockIndex: this.blocks.length,
        spans: [],
        decision: {
          action: "resize",
          bbox: [...bbox],
          type: ""
        }
      });

      this.set.results(results);
      this.set.selectedBlockIndex(this.blocks.length - 1);
    },

    addBlockAtIndex: (index, put = "after") => {
      this._.analytics.logAnalyticsToFirestore({
        action: `block add ${put}`,
        blockIndex: index
      });

      const results = this.clone(this.results);

      const blockAtIndex = results.pages[this.pageIndex].blocks[index];

      const [x0, y0, x1, y1] = blockAtIndex.decision?.bbox ?? blockAtIndex.bbox;

      const height = y1 - y0;

      let bbox;
      let adjustedIndex;

      if (put === "before") {
        bbox = [x0, y0 - height, x1, y1 - height];
        adjustedIndex = index;
      } else if (put === "after") {
        bbox = [x0, y0 + height, x1, y1 + height];
        adjustedIndex = index + 1;
      }

      // insert the new block where it should belong
      results.pages[this.pageIndex].blocks.splice(adjustedIndex, 0, {
        new: true,
        bbox: bbox,
        ogBlockIndex: this.blocks.length,
        spans: [],
        decision: {
          action: "resize",
          bbox: [...bbox],
          type: ""
        }
      });

      this.set.results(results);
      this.set.selectedBlockIndex(adjustedIndex);
      this.set.navigatedBlockIndex(adjustedIndex);
    },
    incrementHeightByCurrentHeight: () => {
      const results = this.clone(this.results);

      const blockToIncrementHeight =
        results.pages[this.pageIndex].blocks[this.navigatedBlockIndex];

      const [x0, y0, x1, y1] =
        blockToIncrementHeight.decision?.bbox ?? blockToIncrementHeight.bbox;

      const height =
        blockToIncrementHeight.bbox[3] - blockToIncrementHeight.bbox[1];

      const newBbox = [x0, y0, x1, y1 + height];

      blockToIncrementHeight.decision = {
        ...blockToIncrementHeight.decision,
        action: "resize",
        bbox: newBbox
      };

      this.set.results(results);
    }
  };
  clone = obj => JSON.parse(JSON.stringify(obj));
  // computed
  get page() {
    return this.results.pages?.[this.pageIndex] ?? {};
  }

  get blocks() {
    return this.page.blocks ?? [];
  }

  // Find out root cause of why this isn't updating atomically, this is to patch an index warning with mobx
  get selectedBlock() {
    if (!this.blocks || this.selectedBlockIndex >= this.blocks.length) {
      return undefined;
    }
    return this.blocks[this.selectedBlockIndex];
  }

  get selectedSpan() {
    return this.selectedBlockSpans?.[this.selectedSpanIndex] ?? undefined;
  }

  get selectedBlockSpans() {
    return (
      this.selectedBlock?.decision?.spans ??
      this.selectedBlock?.spans ??
      undefined
    );
  }

  get selectedBlockInlineMathSpans() {
    return (
      this.selectedBlockSpans?.filter(({ isInlineImage }) => isInlineImage) ??
      undefined
    );
  }

  get navigatedBlock() {
    if (!this.blocks || this.navigatedBlockIndex >= this.blocks.length) {
      return undefined;
    }
    return this.blocks?.[this.navigatedBlockIndex];
  }

  get highlightedBlock() {
    if (!this.blocks || this.navigatedBlockIndex >= this.blocks.length) {
      return undefined;
    }
    return this.blocks[this.highlightedBlockIndex];
  }

  get blockFilter() {
    return typesConceptuallyGrouped[this.classificationGroup].beFilter;
  }

  get loaded() {
    return this.results.paperID !== undefined;
  }
  get pageNumber() {
    return this.pageIndex + 1;
  }

  get undeletedBlocks() {
    return (
      this.blocks?.filter(block => block.decision?.action !== "delete") ?? []
    );
  }

  get inlineMathBlocks() {
    return [...this.results.pages.map(page => page.blocks)]
      .flat()
      .filter(({ spans }) =>
        spans.reduce((acc, span) => span.isInlineImage || acc, false)
      );
  }

  get blocksVisitedCompletion() {
    if (this.onFinalHilPhase) {
      return {
        completed: this.navigatedBlockMap.size === this.inlineMathBlocks.length,
        numberVisited: [...this.navigatedBlockMap.values()].filter(
          ({ deleted }) => !deleted
        ).length,
        total: this.inlineMathBlocks.length
      };
    }

    return {
      completed: this.navigatedBlockMap.size === this.undeletedBlocks.length,
      numberVisited: [...this.navigatedBlockMap.values()].filter(
        ({ deleted }) => !deleted
      ).length,
      total: this.undeletedBlocks.length
    };
  }

  get pagesCheckedCompletion() {
    let checked = 0;

    const checkedPageNumberSet = new Set();
    const uncheckedPageNumberSet = new Set();

    for (const [index, page] of this.results.pages?.map((v, i) => [i, v]) ??
      []) {
      const pageNumber = index + 1;

      if (page.checked) {
        checked++;
        checkedPageNumberSet.add(pageNumber);
        continue;
      }
      uncheckedPageNumberSet.add(pageNumber);
    }
    return {
      completed: checked === this.results.pages?.length ?? false,
      checkedPageNumberSet,
      uncheckedPageNumberSet,
      total: this.results.pages?.length ?? 0
    };
  }

  get pageHasAnUnlabelledBlocks() {
    for (const block of this.blocks ?? []) {
      const hasNoType = !(block?.decision?.type ?? block?.type);
      if (hasNoType) {
        return true;
      }
    }
    return false;
  }

  get typeOfCurrentBlock() {
    const type =
      (this.selectedBlock?.decision?.type ?? this.selectedBlock?.type) ||
      (this.highlightedBlock?.decision?.type ?? this.highlightedBlock?.type);

    return beToFeAliasMap[type];
  }

  get paperIsAlreadyProcessed() {
    return !!this.results.processed;
  }

  get isComplete() {
    return this.results.pages.every(page => page.checked);
  }

  // BE enums for processing phase
  get hilPhase() {
    return this.results.hilPhase;
  }

  get maxHilPhase() {
    return this.results.maxHilPhase;
  }

  get onFinalHilPhase() {
    // return this.hilPhase > 0;
    if (typeof window === "undefined") {
      return this.hilPhase > 0;
    }

    return Router.pathname.includes("review") ? false : this.hilPhase > 0;
  }

  get pdfScale() {
    // required for inline math, it looks ugly and there are way around it, but the most important thing is accuracy right now
    if (this.onFinalHilPhase) {
      return 4;
    }
    return 1;
  }
  get resultsLoaded() {
    return !!this.results.pages;
  }
  get fullyLoaded() {
    return Boolean(
      this.pdfPageLoaded && this.resultsLoaded && this._?.user?.session?.uid
    );
  }
}
