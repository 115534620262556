import { useContext, createContext } from "react";
import { toJS, makeObservable, observable, action, computed } from "mobx";
import { observer, enableStaticRendering } from "mobx-react-lite";
// internal APIs
import Main from "./main";
import Analytics from "./analytics";
import User from "./user";
// signal this is a server
enableStaticRendering(typeof window === "undefined");
//
const storeContext = createContext();
const useStore = () => useContext(storeContext).main;
const StoreProvider = ({ children, init }) => (
  <storeContext.Provider value={getStore(init)}>
    {children}
  </storeContext.Provider>
);
class GlobalStore {
  constructor() {
    function makeMobxStore(store) {
      function reset() {
        const setters = Object.getOwnPropertyNames(store.set);
        let i = setters.length;

        while (i--) {
          store.set[setters[i]]();
        }
      }
      reset();

      const storePrototype = Object.getOwnPropertyDescriptors(
        Object.getPrototypeOf(store)
      );
      const _computed = {};

      for (const prop in storePrototype) {
        if (storePrototype[prop].get !== undefined) {
          _computed[prop] = computed;
        }
      }

      const observables = {};
      const actions = Object.keys(store.set).reduce((actions, prop) => {
        actions[prop] = action;
        observables[prop] = observable;
        return actions;
      }, {});

      makeObservable(store.set, actions);
      makeObservable(store, { ...observables, ..._computed });
      // add to JS as part of all stores
      store.toJS = toJS;
      // return generic reset
      return reset;
    }

    this.main = new Main(makeMobxStore, this);
    this.user = new User(makeMobxStore, this);
    this.analytics = new Analytics(makeMobxStore, this);
  }
}

let globalStore = new GlobalStore();

function getStore(storeInit) {
  const stores = globalStore ?? new GlobalStore();

  if (storeInit) {
    for (const observable in storeInit) {
      stores.main.set[observable](storeInit[observable]);
    }
  }

  // For SSG and SSR always create a new store
  return stores;
}

export { observer, useStore, StoreProvider, globalStore };
