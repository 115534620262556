import Head from "next/head";
// app level config
import { StoreProvider } from "../mobx";
import { Styles } from "../styles/theme";

const App = ({ Component, emotionCache, pageProps }) => (
  <>
    <Head>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
    </Head>
    <Styles emotionCache={emotionCache}>
      <StoreProvider init={pageProps.storeInit}>
        <Component {...pageProps} />
      </StoreProvider>
    </Styles>
  </>
);
export default App;
