// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  addDoc,
  deleteDoc,
  getFirestore,
  collection,
  updateDoc,
  doc,
  setDoc,
  getDocs,
  onSnapshot,
  where,
  limit,
  query,
  orderBy,
  increment,
  runTransaction
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInAnonymously,
  signInWithPopup,
  signInWithCustomToken
} from "firebase/auth";

import Router from "next/router";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAL7D3AhUgWIUn8K-CMwnxiXq1gM6VHX4Y",
  authDomain: "human-in-the-loop-b726d.firebaseapp.com",
  projectId: "human-in-the-loop-b726d",
  storageBucket: "human-in-the-loop-b726d.appspot.com",
  messagingSenderId: "1016226303748",
  appId: "1:1016226303748:web:64da93d94cca2f2b6eca51"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);

const papersCollection = collection(firestore, "papers");

const reportedPapersCollection = collection(firestore, "reportedPapers");

// we allow for papers older than an hour to be reintroduced to a user.
const LAST_MODIFIED_CUTOFF_TIME = new Date(
  new Date().getTime() - 1e3 * 60 * 60
);

function getCurrentPaperSubscription(
  callback = () => undefined,
  { publisher, paperID }
) {
  const unsubscribe = onSnapshot(
    query(doc(firestore, `papers/${publisher}-${paperID}`)),
    doc => {
      const [publisher, paperID] = doc.id.split("-");
      callback({
        publisher,
        paperID,
        ...doc.data()
      });
    }
  );

  return unsubscribe;
}

function getAllPapersSubscription(
  callback = () => undefined,
  { offsetBy, numOfResults, hilPhase, locked }
) {
  const queryArgs = [orderBy("modified", "desc"), limit(10)];

  if (numOfResults) {
    queryArgs.push(limit(numOfResults));
  }
  if (offsetBy) {
    // TODO replace with correct export alias
    // queryArgs.push(offset(offsetBy));
  }
  if (locked) {
    queryArgs.push(where("locked", "==", false));
  }
  if (hilPhase) {
    queryArgs.push(where("hilPhase", "==", hilPhase));
  }

  const unsubscribe = onSnapshot(
    query(papersCollection, ...queryArgs),
    querySnapshot => {
      const docs = [];
      querySnapshot.forEach(doc => {
        const [publisher, paperID] = doc.id.split("-");
        docs.push({
          publisher,
          paperID,
          ...doc.data()
        });
      });
      callback(docs);
    }
  );

  return unsubscribe;
}

async function getNextPaper() {
  const docs = [];

  // get hilPhase 0 papers
  const querySnapshot = await getDocs(
    query(
      papersCollection,
      where("hilPhase", "==", 0),
      where("locked", "==", false),
      where("state", "==", "READY"),
      orderBy("modified", "desc"),
      limit(1)
    )
  );
  // return the first paper you run into
  querySnapshot.forEach(doc => {
    const [publisher, paperID] = doc.id.split("-");
    docs.push({
      publisher,
      paperID,
      ...doc.data()
    });
  });

  // check hilPhase 0 group for stragglers that were locked but never finished
  if (docs.length === 0) {
    const querySnapshot = await getDocs(
      query(
        papersCollection,
        where("hilPhase", "==", 0),
        where("locked", "==", true),
        where("state", "==", "READY"),
        // if it's been more than 60 minutes, the locked paper becomes eligible
        where("modified", "<=", LAST_MODIFIED_CUTOFF_TIME),
        limit(1)
      )
    );
    querySnapshot.forEach(doc => {
      const [publisher, paperID] = doc.id.split("-");
      docs.push({
        publisher,
        paperID,
        ...doc.data()
      });
    });
  }

  // check hilPhase 1 papers
  if (docs.length === 0) {
    const querySnapshot = await getDocs(
      query(
        papersCollection,
        where("hilPhase", "==", 1),
        where("locked", "==", false),
        where("state", "==", "READY"),
        orderBy("modified", "desc"),
        limit(1)
      )
    );
    querySnapshot.forEach(doc => {
      const [publisher, paperID] = doc.id.split("-");
      docs.push({
        publisher,
        paperID,
        ...doc.data()
      });
    });
  }

  // check hilPhase 1 group for stragglers that were locked but never finished
  if (docs.length === 0) {
    const querySnapshot = await getDocs(
      query(
        papersCollection,
        where("hilPhase", "==", 1),
        where("locked", "==", true),
        where("state", "==", "READY"),
        // if it's been more than 60 minutes, the locked paper becomes eligible
        where("modified", "<=", LAST_MODIFIED_CUTOFF_TIME),
        limit(1)
      )
    );
    querySnapshot.forEach(doc => {
      const [publisher, paperID] = doc.id.split("-");
      docs.push({
        publisher,
        paperID,
        ...doc.data()
      });
    });
  }

  // if we still have no papers, we need to check and see if there are any that remain that are not yet in hilphase 2
  // they could be locked and not eligible to be reprocessed yet because their lock timeout has not been reached yet.
  // NOTE this whole query could potentially be optimized, but Firestore only supports 1 exclusive condtion when performing a query
  // which is why so many queries are used right now.
  const docsInProgress = [];

  // see if there are any remaining docs that are not on hilPhase 2 yet, some other user could be working on one and it's not submitted yet
  if (docs.length === 0) {
    const querySnapshot = await getDocs(
      query(
        papersCollection,
        where("hilPhase", "!=", 2),
        where("state", "==", "READY"),
        limit(1)
      )
    );

    querySnapshot.forEach(doc => {
      const [publisher, paperID] = doc.id.split("-");
      docsInProgress.push({
        publisher,
        paperID,
        ...doc.data()
      });
    });
  }

  // If any docs are processing, we're not complete
  if (docs.length === 0) {
    const querySnapshot = await getDocs(
      query(papersCollection, where("state", "==", "PROCESSING"), limit(1))
    );

    querySnapshot.forEach(doc => {
      const [publisher, paperID] = doc.id.split("-");
      docsInProgress.push({
        publisher,
        paperID,
        ...doc.data()
      });
    });
  }

  const [doc] = docs;
  return {
    paper: doc || null,
    allPapersProcessed: docsInProgress.length === 0 && !doc
  };
}

async function reportPaper({ publisher, paperID, description, pageNumber }) {
  await updatePaperRecord({ publisher, paperID, reported: true });

  return addDoc(reportedPapersCollection, {
    publisher,
    paperID,
    description,
    created: new Date(),
    pageNumber: Router.query.page ?? null
  });
}

function deletePaper({ publisher, paperID }) {
  return deleteDoc(doc(firestore, `papers/${publisher}-${paperID}`));
}

async function createPaperRecords() {
  try {
    for (const paperID of paperIDs) {
      const publisher = "arxiv";
      await setDoc(doc(firestore, "papers", `${publisher}-${paperID}`), {
        hilPhase: 0,
        created: new Date(),
        modified: new Date(),
        state: "READY",
        locked: false
      });
      console.log("Document written with ID: ", `${publisher}-${paperID}`);
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function updatePaperRecord({ publisher, paperID, locked, reported }) {
  // TODO we probably don't want to overload the error state, we'll be able to cross reference the reports against the state's to do our final pass
  // but a better approach would be to add an additional prop to docs called "reported: true | false"
  if (reported === true) {
    await updateDoc(doc(firestore, "papers", `${publisher}-${paperID}`), {
      locked: true,
      modified: new Date(),
      state: "ERROR"
    });
  }

  // unlocking of documents just makes them available to the next user, so there are no race conditions when unlocking
  if (locked === false) {
    await updateDoc(doc(firestore, "papers", `${publisher}-${paperID}`), {
      locked,
      modified: new Date()
    });
    return true;
  }
  // whenever a paper is locked, we want to run a transaction,
  // if another user has already locked the document the current user is attempting to mark as locked, we can end up having two users accessing the same paper at the same time
  const success = await runTransaction(firestore, async transaction => {
    const docToLock = await transaction.get(
      doc(firestore, "papers", `${publisher}-${paperID}`)
    );

    const isLocked = docToLock.get("locked");
    const modified = docToLock.get("modified").toDate();

    // if the paper is locked, they should not access it
    if (isLocked && modified > LAST_MODIFIED_CUTOFF_TIME) {
      return false;
    }

    // otherwise they should lock it, as they're about to access it
    await transaction.update(
      doc(firestore, "papers", `${publisher}-${paperID}`),
      {
        locked,
        modified: new Date()
      }
    );
    return true;
  });

  return success;
}

// NIPS 2021 paperIDs
// const paperIDs = [
//   // "1710.10903",
//   // "1706.03762",
//   // "1912.03310"
//   // "2110.14628",
//   // "2209.03943",
//   // "2106.02734"
//   // "2108.11204",
//   // "2108.11204",
//   // "2106.09129",
//   // "2110.00218",
//   // "2106.07153",
//   // "2107.14344",
//   // "2104.12112",
//   // "2107.01264",
//   // "2103.07945",
//   // "2106.07153",
//   "2107.02156",
//   "2108.11204"
//   // "2107.02510",
//   // "2107.04150",
//   // "2110.00218"
// ];

// NIPS 2020 paperIDs

const paperIDs = [
  // "1912.11615",
  // "2006.06752",
  // "2006.16228",
  // "2009.12919",
  // "2002.11642",
  // "2006.05553",
  // "2006.12631",
  "2002.00189",
  "2012.03528",
  "2101.08809",
  "2006.07340"
];

const Auth = getAuth(app);

export const auth = {
  Auth,
  // OAuthProvider,
  // GoogleAuthProvider,
  // linkWithCredential,
  onAuthStateChanged: callback => onAuthStateChanged(Auth, callback),
  signOut: () => signOut(Auth),
  signInAnonymously: () => signInAnonymously(Auth),
  signInWithPopup: provider => signInWithPopup(Auth, provider),
  signInWithCustomToken: customToken => signInWithCustomToken(Auth, customToken)
};

export {
  firestore,
  doc,
  collection,
  setDoc,
  addDoc,
  getDocs,
  getNextPaper,
  getAllPapersSubscription,
  getCurrentPaperSubscription,
  createPaperRecords,
  deletePaper,
  updatePaperRecord,
  increment,
  reportPaper
};
