import { alpha } from "@mui/material";

const beBodyTypes = {
  BODY: "BODY",
  SUB_HEADING: "SUB_HEADING",
  INDENTED_BODY: "INDENTED_BODY",
  WRAPPING_BODY: "WRAPPING_BODY",
  LIST: "LIST",
  SPECIAL_TEXT: "SPECIAL_TEXT"
};

const beTypes = {
  // FUNCTIONAL_TEXT
  TITLE: "TITLE",
  HEADING: "HEADING",
  CAPTION: "CAPTION",
  REFERENCE: "REFERENCE",
  // Presented to users as "AUTHORS"
  META_BLOCK: "META_BLOCK",
  // BODY
  ...beBodyTypes,
  // IMAGES
  IMAGE_BLOCK: "IMAGE_BLOCK",
  STANDALONE_MATH: "STANDALONE_MATH",
  TABLE: "TABLE",
  // "SECONDARY TEXT"
  FOOTNOTE: "FOOTNOTE",
  FOOTER: "FOOTER",
  HEADER: "HEADER",
  PRINT_NOTICE: "PRINT_NOTICE",
  DIVIDER_BLOCK: "DIVIDER_BLOCK",
  TOC: "TOC"
};

const feTypes = {
  TITLE: "Title",
  HEADING: "Heading",
  SUB_HEADING: "Sub Heading",
  CAPTION: "Caption",
  REFERENCE: "Reference",
  // Authors (meta block)
  AUTHORS: "Authors",
  // BODY (groups together many things that are body)
  PARAGRAPH: "Paragraph",
  // IMAGES
  IMAGE: "Image",
  STANDALONE_MATH: "Standalone Math",
  TABLE: "Table",
  // Secondary Text
  FOOTNOTE: "Footnote",
  FOOTER: "Footer",
  HEADER: "Header",
  PRINT_NOTICE: "Print Notice",
  DIVIDER: "Divider",
  TOC: "TOC"
};

// TODO make these maps a circular structure so you can go from FE to BE without additional DS's
const feToBeAliasMap = {
  // FUNCTIONAL TEXT
  [feTypes.TITLE]: beTypes.TITLE,
  [feTypes.HEADING]: beTypes.HEADING,
  [feTypes.SUB_HEADING]: beTypes.SUB_HEADING,
  [feTypes.CAPTION]: beTypes.CAPTION,
  [feTypes.REFERENCE]: beTypes.REFERENCE,
  // Authors (meta block)
  [feTypes.AUTHORS]: beTypes.META_BLOCK,
  // BODY (groups together many things that are body)
  [feTypes.PARAGRAPH]: beTypes.BODY,
  // IMAGES
  [feTypes.IMAGE]: beTypes.IMAGE_BLOCK,
  [feTypes.STANDALONE_MATH]: beTypes.STANDALONE_MATH,
  [feTypes.TABLE]: beTypes.TABLE,
  // Secondary Text
  [feTypes.FOOTNOTE]: beTypes.FOOTNOTE,
  [feTypes.FOOTER]: beTypes.FOOTER,
  [feTypes.HEADER]: beTypes.HEADER,
  [feTypes.PRINT_NOTICE]: beTypes.PRINT_NOTICE,
  [feTypes.DIVIDER]: beTypes.DIVIDER_BLOCK,
  [feTypes.TOC]: beTypes.TOC
};

const beToFeAliasMap = {
  // FUNCTIONAL_TEXT
  [beTypes.TITLE]: feTypes.TITLE,
  [beTypes.HEADING]: feTypes.HEADING,
  [beTypes.CAPTION]: feTypes.CAPTION,
  [beTypes.REFERENCE]: feTypes.REFERENCE,
  [beTypes.SUB_HEADING]: feTypes.SUB_HEADING,
  // Presented to users as "AUTHORS"
  [beTypes.META_BLOCK]: feTypes.AUTHORS,
  // BODY
  [beTypes.BODY]: feTypes.PARAGRAPH,
  [beTypes.INDENTED_BODY]: feTypes.PARAGRAPH,
  [beTypes.WRAPPING_BODY]: feTypes.PARAGRAPH,
  [beTypes.LIST]: feTypes.PARAGRAPH,
  [beTypes.SPECIAL_TEXT]: feTypes.PARAGRAPH,
  // IMAGES
  [beTypes.IMAGE_BLOCK]: feTypes.IMAGE,
  [beTypes.STANDALONE_MATH]: feTypes.STANDALONE_MATH,
  [beTypes.TABLE]: feTypes.TABLE,
  // "SECONDARY TEXT"
  [beTypes.FOOTNOTE]: feTypes.FOOTNOTE,
  [beTypes.FOOTER]: feTypes.FOOTER,
  [beTypes.HEADER]: feTypes.HEADER,
  [beTypes.PRINT_NOTICE]: feTypes.PRINT_NOTICE,
  [beTypes.DIVIDER_BLOCK]: feTypes.DIVIDER,
  [beTypes.TOC]: feTypes.TOC
};

const ULTRA_HEAVY_OPACITY = 0.3;
const HEAVY_OPACITY = 0.2;
const LIGHT_OPACITY = 0.1;

const colors = {
  RED: alpha("rgb(255, 0,0)", HEAVY_OPACITY),
  ORANGE: alpha("rgb(255,127,0)", ULTRA_HEAVY_OPACITY),
  YELLOW: alpha("rgb(255,255,0)", LIGHT_OPACITY),
  GREEN: alpha("rgb(0,255,0)", LIGHT_OPACITY),
  BLUE: alpha("rgb(0,0,255)", LIGHT_OPACITY),
  CYAN: alpha("rgb(0,255,255)", LIGHT_OPACITY),
  PINK: alpha("rgb(255, 100, 130)", LIGHT_OPACITY),
  INDIGO: alpha("rgb(75,0,130)", LIGHT_OPACITY),
  VIOLET: alpha("rgb(148,0,211)", LIGHT_OPACITY),
  GREY: alpha(`rgb(${Array(3).fill(200).join(",")})`, LIGHT_OPACITY)
};

const colorGroupings = {
  // BODY
  [feTypes.PARAGRAPH]: colors.BLUE,
  // Presented to users as "AUTHORS"
  [feTypes.AUTHORS]: colors.PINK,
  [feTypes.CAPTION]: colors.RED,
  // FUNCTIONAL_TEXT
  [feTypes.TITLE]: colors.RED,
  [feTypes.HEADING]: colors.RED,
  [feTypes.SUB_HEADING]: colors.PINK,

  [feTypes.REFERENCE]: colors.ORANGE,
  // IMAGES
  [feTypes.IMAGE]: colors.YELLOW,
  [feTypes.STANDALONE_MATH]: colors.YELLOW,
  [feTypes.TABLE]: colors.YELLOW,

  // "SECONDARY TEXT"
  [feTypes.FOOTNOTE]: colors.GREY,
  [feTypes.FOOTER]: colors.GREY,
  [feTypes.HEADER]: colors.GREY,
  [feTypes.PRINT_NOTICE]: colors.GREY,
  [feTypes.DIVIDER]: colors.GREY,
  [feTypes.TOC]: colors.GREY
};

export const feDefinitions = {
  // BODY
  [feTypes.PARAGRAPH]: {
    definition: "Text that comprises the majority of a document.",
    details:
      "It is best understood as the most non unique text you find in a document. It does not have any special purpose other than to deliver the information in the paper."
  },
  // Presented to users as "AUTHORS"
  [feTypes.AUTHORS]: {
    definition: "Should contain all of the authors, always on the first page.",
    details: "Typically immediately under the title."
  },
  [feTypes.CAPTION]: {
    definition: "Text that describes an image.",
    details:
      'Sits immediately adjacent to an image, typically starting with "Figure", "Table", or "Listing".'
  },
  // FUNCTIONAL_TEXT
  [feTypes.TITLE]: {
    definition: "Title of the document.",
    details: ""
  },
  [feTypes.HEADING]: {
    definition: 'Text that specifies a high level "section" of a paper.',
    details:
      "This is text that stands by itself, e.g. Abstract, Introduction, Section A, References, Appendix"
  },
  [feTypes.SUB_HEADING]: {
    definition: "Text that comprises the majority of a document.",
    details: "Different from Headings in that it is not top level."
  },

  [feTypes.REFERENCE]: {
    definition: "This is text that is a citation.",
    details:
      "Located in the references section of a paper, this is any text that includes reference material to other works."
  },
  // IMAGES
  [feTypes.IMAGE]: {
    definition: "Anything that looks like it was included as a piece of media.",
    details: ""
  },
  [feTypes.STANDALONE_MATH]: {
    definition:
      "Math formulas that are separate from the paragraphs of the work.",
    details: "These are large font formulas placed between paragraphs."
  },
  [feTypes.TABLE]: {
    definition:
      "Text represented in columns and rows, typically with lines outlining the columns and rows.",
    details: ""
  },

  // "SECONDARY TEXT"
  [feTypes.FOOTNOTE]: {
    definition:
      "At the bottom of text columns that is referred to within those columns.",
    details: ""
  },
  [feTypes.FOOTER]: {
    definition:
      "At the very end of a page, marking the bottom boundary of the page, often times a page number",
    details: ""
  },
  [feTypes.HEADER]: {
    definition:
      "At the very top of a page, marking the upper boundary of the page, sometimes will include the page number of publisher information.",
    details: ""
  },
  [feTypes.PRINT_NOTICE]: {
    definition:
      "Typically at the bottom of the first page, used to relay information about the journal the paper is a part of or the conference it was presented at.",
    details: ""
  },
  [feTypes.DIVIDER]: {
    definition:
      "Separates one part of text from another, these should be removed if encountered.",
    details: ""
  },
  [feTypes.TOC]: {
    definition: "Table of contents entry",
    details: ""
  }
};

const typesConceptuallyGrouped = [
  {
    name: "All",
    types: Object.fromEntries(
      Object.values(feTypes).map(name => [
        name,
        {
          alias: feToBeAliasMap[name]
        }
      ])
    ),
    beFilter: Object.values(beTypes)
  },
  {
    name: "Sections",
    types: {
      [feTypes.TITLE]: {
        alias: beTypes.TITLE
      },
      [feTypes.HEADING]: {
        alias: beTypes.HEADING
      },
      [feTypes.CAPTION]: {
        alias: beTypes.CAPTION
      },
      [feTypes.SUB_HEADING]: {
        alias: beTypes.SUB_HEADING
      }
    },
    beFilter: [beTypes.TITLE, beTypes.HEADING, beTypes.SUB_HEADING]
  },
  {
    name: "Body",
    types: {
      [feTypes.PARAGRAPH]: {
        alias: beTypes.BODY
      },
      [feTypes.REFERENCE]: {
        alias: beTypes.REFERENCE
      },
      [feTypes.AUTHORS]: {
        alias: beTypes.META_BLOCK
      }
    },
    beFilter: [
      ...Object.values(beBodyTypes),
      beTypes.META_BLOCK,
      beTypes.META_BLOCK
    ]
  },
  {
    name: "Images",
    types: {
      [feTypes.IMAGE]: {
        alias: beTypes.IMAGE_BLOCK
      },
      [feTypes.STANDALONE_MATH]: {
        alias: beTypes.STANDALONE_MATH
      },
      [feTypes.TABLE]: {
        alias: beTypes.TABLE
      }
    },
    beFilter: [
      beTypes.IMAGE_BLOCK,
      beTypes.STANDALONE_MATH,
      beTypes.TABLE,
      beTypes.CAPTION
    ]
  },
  {
    name: "Secondary Text",
    types: {
      [feTypes.FOOTNOTE]: {
        alias: beTypes.FOOTNOTE
      },
      [feTypes.FOOTER]: {
        alias: beTypes.FOOTER
      },
      [feTypes.HEADER]: {
        alias: beTypes.HEADER
      },
      [feTypes.PRINT_NOTICE]: {
        alias: beTypes.PRINT_NOTICE
      },
      [feTypes.DIVIDER]: {
        alias: beTypes.DIVIDER_BLOCK
      },
      [feTypes.TOC]: {
        alias: beTypes.TOC
      }
    },
    beFilter: [
      beTypes.FOOTNOTE,
      beTypes.FOOTER,
      beTypes.HEADER,
      beTypes.PRINT_NOTICE,
      beTypes.DIVIDER_BLOCK,
      beTypes.TOC
    ]
  }
];

const determineColorByBboxType = block => {
  const type = beToFeAliasMap[block?.decision?.type ?? block.type];

  const color = colorGroupings[type];

  // TODO this is for safety, should be removed once everything is confirmed to be working
  return color ? color : "rgb(100,100,100, 0.2)";
};

export {
  determineColorByBboxType,
  beTypes,
  feTypes,
  feToBeAliasMap,
  beToFeAliasMap,
  typesConceptuallyGrouped,
  colors,
  colorGroupings
};
