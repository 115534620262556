import { CacheProvider } from "@emotion/react";
import {
  createTheme,
  responsiveFontSizes,
  colors,
  alpha,
  GlobalStyles,
  CssBaseline,
  ThemeProvider
} from "@mui/material";

import { clientSideEmotionCache } from "./createEmotionCache";

const theme = (() => {
  const typography = {
    brand: "ff-cocon-pro, sans-serif",
    fontFamily: "SF Pro Display, Roboto, Helvetica, Arial, sans-serif",
    serif: "New York Medium, Roboto Serif, serif",
    h5: {
      fontSize: 24,
      fontWeight: "bold",
      letterSpacing: 0
    },
    h6: {
      fontSize: 20,
      fontWeight: "bold",
      letterSpacing: 0.15
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0.15
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.1
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0
    },
    button: {
      textTransform: "unset",
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0
    },
    overline: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 1.5
    },
    paperBody1: {
      fontSize: 16,
      letterSpacing: 0,
      fontFamily: "New York Medium, Roboto Serif, serif"
    },
    paperBody2: {
      fontSize: 14,
      letterSpacing: 0,
      fontFamily: "New York Medium, Roboto Serif, serif"
    },
    paperHighlight: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
      fontFamily: "New York Medium, Roboto Serif, serif"
    },
    lineClamp: lines => ({
      lineClamp: lines,
      WebkitLineClamp: lines,
      wordBreak: "break-word",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical"
    })
  };
  // const genericTheme = createTheme({ typography });

  return responsiveFontSizes(
    createTheme({
      typography,
      fadeEdge: (direction = "bottom", percent = 90, degree) => ({
        WebkitMaskImage: `linear-gradient(${
          degree || `to ${direction}`
        }, #000 ${percent}%, transparent)`
      }),
      palette: {
        background: {
          default: "#FFFBFE"
        },
        primary: {
          main: "#6750A4"
        },
        secondary: {
          main: "#625B71"
        },
        error: {
          main: colors.red.A400
        },
        colors: {
          alpha,
          primary: {
            color: "#6750A4",
            container: "#EADDFF",
            container2: "#EAE3F1",
            on: { color: "#fff", container: "#21005D" }
          },
          secondary: {
            color: "#625B71",
            container: "#E8DEF8",
            on: { color: "#fff", container: "#1D192B" }
          },
          tertiary: {
            color: "#7D5260",
            container: "#FFD8E4",
            on: { color: "#fff", container: "#31111D" }
          },
          error: {
            color: "#B3261E",
            container: "#F9DEDC",
            box: "#F9DEF9",
            on: { color: "#fff", container: "#410E0B" }
          },
          background: {
            color: "#FFFBFE",
            on: "#1C1B1F"
          },
          outline: "#79747E",
          surface: {
            color: "#FFFBFE",
            variant: "#E7E0EC",
            on: { color: "#1C1B1F", variant: "#49454F" }
          }
        }
      },
      components: {
        MuiIconButton: {
          styleOverrides: {
            colorInherit: {
              color: colors.grey[700]
            }
          }
        },
        MuiChip: {
          variants: [
            {
              props: { variant: "filled" },
              style: {
                backgroundColor: colors.grey[200]
              }
            }
          ],
          styleOverrides: {
            label: {
              ...typography.body2,
              color: "unset"
            }
          }
        }
      }
    })
  );
})();
const globalOverrides = (
  <GlobalStyles
    styles={{
      html: {
        body: { minHeight: "100vh" },
        [theme.breakpoints.down("sm")]: {
          "& *": {
            WebkitTouchCallout: "none",
            textSizeAdjust: "none",
            maxHeight: 9007199254740991
          }
        },
        a: {
          textDecoration: "none"
        },
        scrollbarColor: [[theme.palette.primary.light, "transparent"]],
        "*::-webkit-scrollbar": {
          width: theme.spacing(0.75),
          height: theme.spacing(0.75)
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: theme.palette.colors.alpha(
            theme.palette.primary.light,
            0.2
          )
        },
        "*::-webkit-scrollbar-corner": {
          backgroundColor: "transparent"
        },
        "*:focus": {
          outline: "none"
        }
      },
      input: {
        [theme.breakpoints.down("sm")]: {
          // iOS zooms-in, if fontSize is smaller than 16; prevent that
          fontSize: "16px !important"
        }
      },
      "& .MuiPaper-root.MuiCard-root": {
        borderRadius: 8
      },
      ".MuiButtonBase-root.MuiButton-root": {
        textTransform: "none"
      },
      ".MuiListSubheader-root": {
        backgroundColor: "transparent"
      }
    }}
  />
);

export { theme, globalOverrides };

export const Styles = ({ emotionCache = clientSideEmotionCache, children }) => (
  <CacheProvider value={emotionCache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalOverrides}
      {children}
    </ThemeProvider>
  </CacheProvider>
);
