import createCache from "@emotion/cache";
import createEmotionServer from "@emotion/server/create-instance";

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export const createEmotionCache = () =>
  createCache({
    key: "css",
    prepend: true,
    insertionPoint:
      typeof window === "undefined"
        ? undefined
        : document.querySelector('meta[name="emotion-insertion-point"]')
  });

export const getEmotionStyles = () => {
  // You can consider sharing the same Emotion cache between all the SSR requests to speed up performance.
  // However, be aware that it can have global side effects.
  const cache = createEmotionCache();
  const { extractCriticalToChunks } = createEmotionServer(cache);

  return { cache, getStyles: extractCriticalToChunks };
};
export const toTags = style => (
  <style
    key={style.key}
    data-emotion={`${style.key} ${style.ids.join(" ")}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: style.css }}
  />
);
// Client-side cache, shared for the whole session of the user in the browser.
export const clientSideEmotionCache = createEmotionCache();
